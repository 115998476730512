import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppThunkAPI } from 'store/ThunkAPIType';
import getConfig from 'next/config';
import * as Sentry from '@sentry/nextjs';
import { isomorphicGetCookie } from 'IsomorphicUtils';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { initApollo } from 'lib/apollo';
import {
  GetBillingDetailsDocument,
  GetChargifyFieldsDocument,
  GetChargifyFieldsQuery,
  GetUserSubscriptionPreviewDocument,
} from 'lib/graphql/MyAccount/__generated__/MyAccountQueries.generated';
import FormSwiftAPIClient from 'FormSwiftAPIClient';
import { refreshChargifyJSSecurityToken } from 'CheckoutPage/state/actions/refreshChargifyJSSecurityToken';

const {
  publicRuntimeConfig: { API_URL, DOMAIN_URL, INTERNAL_API_URL },
} = getConfig();

export const updateCard = createAsyncThunk<
  Awaited<unknown> | { error: string },
  { token: string },
  AppThunkAPI & {
    value: string;
    rejectValue: { error: string };
  }
>('updateCard', async (data, thunkAPI) => {
  const requestData = {
    chargify_token: data.token,
    user_id: isomorphicGetCookie('userid'),
    user_token: isomorphicGetCookie('userToken'),
  };
  const formSwiftAPIClient = new FormSwiftAPIClient(API_URL, INTERNAL_API_URL);
  const apolloClient = initApollo(
    {},
    ''
  ) as ApolloClient<NormalizedCacheObject>;
  const {
    data: chargifyFieldsData,
  } = await apolloClient.query<GetChargifyFieldsQuery>({
    query: GetChargifyFieldsDocument,
    variables: {
      isUpdatingPaymentInfo: true,
      site: DOMAIN_URL,
    },
  });
  try {
    const paymentProfileJson: {
      status: string;
      data: // eslint-disable-next-line camelcase
      | { client_secret: string; stripe_publishable_key: string }
        | Record<string, never>;
    } = await formSwiftAPIClient.request(
      'POST',
      '/subscription/change_payment_profile',
      requestData
    );
    if (
      paymentProfileJson.status === 'success' &&
      'client_secret' in paymentProfileJson.data &&
      'stripe_publishable_key' in paymentProfileJson.data &&
      'payment_profile_id' in paymentProfileJson.data
    ) {
      const stripe = window.Stripe(
        paymentProfileJson.data.stripe_publishable_key
      );
      const clientSecret = paymentProfileJson.data.client_secret;
      const newPaymentProfileId = paymentProfileJson.data.payment_profile_id;
      const returnUrl = `https://${
        DOMAIN_URL as string
      }/my-account?3dsecure=true`;
      const stripeResult = await stripe.confirmSetup({
        clientSecret,
        confirmParams: {
          return_url: returnUrl,
        },
        redirect: 'if_required',
      });
      if (stripeResult.setupIntent.status === 'succeeded') {
        await formSwiftAPIClient.request(
          'POST',
          '/subscription/change_payment_profile/3d-secure/confirm',
          {
            payment_profile_id: newPaymentProfileId,
            setup_intent_id: stripeResult.setupIntent.id,
            user_id: isomorphicGetCookie('userid'),
            user_token: isomorphicGetCookie('userToken'),
          }
        );
      } else {
        await thunkAPI.dispatch(
          refreshChargifyJSSecurityToken({
            chargifyDomain: chargifyFieldsData?.chargifyFields
              ?.domain as string,
          })
        );
        return thunkAPI.rejectWithValue({
          error:
            'Something went wrong. Please try again or contact customer service.',
        });
      }
    }
  } catch (e) {
    Sentry.captureException(e);
    await thunkAPI.dispatch(
      refreshChargifyJSSecurityToken({
        chargifyDomain: chargifyFieldsData?.chargifyFields?.domain as string,
      })
    );
    return thunkAPI.rejectWithValue({
      error:
        'Something went wrong. Please try again or contact customer service.',
    });
  } finally {
    await apolloClient.refetchQueries({
      include: [GetBillingDetailsDocument, GetUserSubscriptionPreviewDocument],
    });
  }
  return null;
});
